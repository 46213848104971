.MainWidth {
    width: 1300px;
    padding: 17px;
}

.PaddinggLeftt {
    padding-left: 22px;
}

.PaddinggLeftIcon {
    padding-left: 10px;
}

.PaddinggLefttIcon {
    padding-left: 150px;
}

.Padding-Main {
    padding: 17px;
}

.mainTopMargin {
    margin-bottom: 0px;
}

.BGMainImg {
    height: 100vh;
}

.VideoMainTag {
    position: absolute;
    top: auto;
    right: 0%;
}

.VideoMainHeight {
    height: 700px;
    width: 100vw;
}

.BOOKINGHEADER {
    color: #fff;
    cursor: pointer;
}

.BOOKINGHEADER:hover {
    color: #212121;
}

.roboto-regular {
    font-family: "Roboto", sans-serif;
}

.laila-semibold {
    font-family: "Laila", serif;
    font-weight: 600;
    font-style: normal;
}

.laila-bold {
    font-family: "Laila", serif;
    font-weight: 700;
    font-style: normal;
}

.FamilyOwned {
    font-size: 24px;
    line-height: 34px;
    color: #151515;
    font-weight: 600;
    margin-bottom: 8px;
}

.MainPositionTop {
    position: relative;
}

.MainPositionTop img {
    width: 100%;
    height: 550px;
}

.MainPositionBottom {
    position: absolute;
    top: 8%;
    right: 4%;
    background-color: #fff;
    max-width: 420px;
    width: 100%;
    padding: 40px 40px 44px;
    border-radius: 10px;
    line-height: 27px;
}

.StoryAboutCompony {
    width: 30%;
}

.ABOUT2IMG {
    margin-left: -270px;
}

.CALLBACK {
    width: 100%;
}

.navbar-light .navbar-toggler {
    border: 1px solid white;
    border-radius: 0px 0px 0px 0px;
}


.WeProvide {
    font-size: 48px;
    line-height: 1.2em;
    color: #151515;
}

.PaddingLeft {
    padding-left: 50px;
}

.SearchSize {
    height: 43px;
    width: 43px;
    border-radius: 50%;
    background-color: #004DA1;
    font-size: 15px;
    color: #fff;
    cursor: pointer;
}

.OurMissionVission {
    padding: 50px;
}

.navbar-light .navbar-nav .nav-link {
    color: #151515;
    cursor: pointer;
    font-size: 15px;
}

.navbar-light .navbar-nav .nav-link:hover {
    color: #65cef5;
    cursor: pointer;
    font-size: 15px;
}

.FooterHover {
    color: #ccdbeb;
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
}

.FooterHover:hover {
    color: #fff;
    font-size: 15px;
    line-height: 19px;
    font-weight: 600;
}

.EmailHover {
    color: #ccdbeb;
}

.EmailHover:hover {
    color: #fff;
}

.NumberHover {
    color: #ccdbeb;
}

.NumberHover:hover {
    color: #fff;
}


.ProductBox {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    padding: 30px;
    border-radius: 2px;
    border-left: 4px solid #fff;
}

.ProductBox:hover {
    background-color: #fff;
    cursor: pointer;
    border-radius: 2px;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    padding: 20px;
    border-left: 4px solid #22B678;
}

.ReadMore button {
    background-color: #fff;
    box-shadow: 0 1rem 3rem rgba(0, 0, 0, .175);
    border: 2px solid black;
    color: #151515;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}

.ReadMore button:hover {
    background-color: black;
    color: #fff;
    border: 2px solid black;
    margin-top: 10px;
    font-size: 14px;
    padding: 6px 20px;
    margin-bottom: 25px;
}

.ProductText {
    color: #656565;
    font-size: 13px;
    text-align: center;
}

.ProductText:hover {
    color: red;
    font-size: 13px;
    text-align: center;
}

.MainTag {
    margin-top: 20px;
}

.MainTag h1 {
    font-size: 34px;
    line-height: 1.2em;
    color: #65cef5;
}


@media screen and (max-width:1286px) {
    .MainWidth {
        width: 98%;
    }

    .BGMainImg {
        height: 80vh;
    }

    .VideoMainHeight {
        height: 750px;
        width: 100vw;
    }

    .MainTag {
        margin-top: 140px;
    }
}

@media screen and (max-width:1240px) {
    .MainWidth {
        width: 99%;
    }

    .PaddinggLefttIcon {
        padding-left: 140px;
    }

    .MainTag {
        margin-top: 150px;
    }

    .VideoMainHeight {
        height: 750px;
        width: 100vw;
    }
}

@media screen and (max-width:1215px) {
    .MainWidth {
        width: 98%;
    }

    .PaddinggLeftt {
        padding-left: 15px;
    }

    .PaddinggLefttIcon {
        padding-left: 130px;
    }

    .VideoMainHeight {
        height: 700px;
        width: 100vw;
    }

    .MainTag {
        margin-top: 100px;
    }
}

@media screen and (max-width:1200px) {

    .MainWidth {
        width: 99.5%;
        padding: 15.5px;
    }

    .mainTopMargin {
        margin-bottom: -5px;
        padding-bottom: 10px;
    }

    .VideoMainTag {
        position: absolute;
        top: auto;
        right: 0%;
    }

    .PaddingLeft {
        padding-left: 25px;
    }

    .Padding-Main {
        padding: 15px;
    }

    .PaddinggLeftt {
        padding-left: 22px;
    }

    .PaddinggLefttIcon {
        padding-left: 60px;
    }

    .MainTag {
        margin-top: 100px;
    }

    .VideoMainHeight {
        height: 700px;
        width: 100vw;
    }

}

@media screen and (max-width:1130px) {
    .MainWidth {
        width: 99.5%;
    }

    .PaddinggLeftt {
        padding-left: 15px;
    }

    .PaddinggLefttIcon {
        padding-left: 35px;
    }

    .VideoMainHeight {
        height: 650px;
        width: 100vw;
    }

    .MainTag {
        margin-top: 50px;
    }
}

@media screen and (max-width:1055px) {
    .MainWidth {
        width: 99.5%;
    }

    .PaddinggLeftt {
        padding-left: 10px;
    }

    .PaddinggLefttIcon {
        padding-left: 0px;
    }

    .MainTag {
        margin-top: 10px;
    }

    .VideoMainHeight {
        height: 600px;
        width: 100vw;
    }
}

@media screen and (max-width:990px) {
    .mainMargin {
        margin-bottom: 0px;
    }

    .VideoMainHeight {
        height: 530px;
        width: 100vw;
    }

    .mainTopMargin {
        margin-bottom: 0px;
    }

    .navbar {
        padding: 0px;
    }

    .StoryAboutCompony {
        width: 26%;
        padding: 20px 20px 15px;
        background-color: #65CDF4;
        border-radius: 7px 7px 0px 0px;
    }

    .ABOUT2IMG {
        margin-left: 0px;
        width: 100%;
    }

    .VideoMainTag {
        position: absolute;
        top: auto;
        right: 0%;
    }

    /* .VideoMainHeight {
        height: 101vh;
    } */

    

    .Padding-Main {
        padding: 16px;
    }

    .MainTag {
        margin-top: -30px;
    }

}

@media screen and (max-width:767px) {
    .MainPositionTop {
        position: relative;
    }

    .MainPositionTop img {
        width: 100%;
        height: 560px;
    }

    .MainPositionBottom {
        position: absolute;
        top: 2%;
        right: 2%;
        background-color: #fff;
        max-width: 335px;
        width: 100%;
        padding: 20px 25px 28px;
        border-radius: 10px;
        line-height: 20px;
    }

    .StoryAboutCompony {
        width: 100%;
        background-color: #65CDF4;
        border-radius: 7px 7px 0px 0px;
        padding: 20px 20px 15px;
    }

    .ABOUT2IMG {
        margin-left: 0px;
        width: 100%;
    }

    .CALLBACK {
        width: 100%;
    }

    .WeProvide {
        font-size: 42px;
        line-height: 1.2em;
        color: #151515;
        font-weight: 600;
    }

    .SearchSize {
        height: 53px;
        width: 53px;
        border-radius: 50%;
    }

    .VideoMainTag {
        position: absolute;
        top: auto;
        right: 0%;
    }

    .VideoMainHeight {
        height: 440px;
        width: 100vw;
    }

    .MainTag {
        margin-top: -70px;
    }

}

@media screen and (max-width:580px) {
    .MainPositionTop {
        position: relative;
    }

    .MainPositionTop img {
        width: 100%;
        height: 480px;
    }

    .MainPositionBottom {
        position: absolute;
        top: 2%;
        left: 1%;
        right: 1%;
        background-color: #fff;
        max-width: 98%;
        width: 100%;
        padding: 15px 15px 10px 20px;
        border-radius: 10px;
        line-height: 20px;
    }

    .StoryAboutCompony {
        width: 100%;
        background-color: #65CDF4;
        border-radius: 7px 7px 0px 0px;
        padding: 20px 20px 15px;
    }

    .ABOUT2IMG {
        margin-left: 0px;
        width: 100%;
    }

    .CALLBACK {
        width: 100%;
    }

    .WeProvide {
        font-size: 32px;
        line-height: 1.2em;
        color: #151515;
        font-weight: 600;
        margin-bottom: 20px;
    }

    .SearchSize {
        height: 53px;
        width: 53px;
        border-radius: 50%;
    }

    .VideoMainTag {
        position: absolute;
        top: auto;
        right: 0%;
    }

    .MainTag {
        margin-top: 0px;
    }

    .MainTag h1 {
        font-size: 24px;
        line-height: 1.2em;
        color: #65cef5;
    }
}